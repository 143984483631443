import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Dashboard from './dashboard';
import withAdminLayout from '../../layout/withAdminLayout';

const Projects1 = lazy(() => import('./projects'));
const Settings = lazy(() => import('../../container/profile/settings/Settings'));
const Invite = lazy(() => import('../../container/profile/settings/overview/Invite'));

const CreateSlots = lazy(() => import('../../container/profile/settings/overview/CreateSlots'));
const ForHomeSnippet = lazy(() => import('../../container/profile/settings/overview/ForHomeSnippet'));
const ForCitySnippet = lazy(() => import('../../container/profile/settings/overview/ForCitySnippet'));
const ForQueueSnippet = lazy(() => import('../../container/profile/settings/overview/ForQueueSnippet'));
const ForArtistSnippet = lazy(() => import('../../container/profile/settings/overview/ForArtistSnippet'));
const ForCollectorSnippet = lazy(() => import('../../container/profile/settings/overview/ForCollectorSnippet'));
const AdminAccount = lazy(() => import('../../container/profile/settings/overview/AdminAccount'));
const ForCheckOutSnippet = lazy(() => import('../../container/profile/settings/overview/ForCheckOutSnippets'));
const LoginHomeGallery = lazy(() => import('../../container/profile/authentication/overview/LoginHomeGallery'));
const LoginTasteQuiz = lazy(() => import('../../container/profile/authentication/overview/LoginTasteQuiz'));

const UserList = lazy(() => import('../../container/profile/settings/overview/UsersList'));
const ArtWorks = lazy(() => import('../../container/profile/settings/overview/ArtWorks'));
// const ArtWorksActions = lazy(() => import('../../container/profile/settings/overview/WorkActions'));
const WorkRatingList = lazy(() => import('../../container/profile/settings/overview/WorkRatingList'));
const Home = lazy(() => import('../../container/profile/authentication/overview/Home'));
const Cart = lazy(() => import('../../container/profile/authentication/overview/Cart'));
const Checkout = lazy(() => import('../../container/profile/authentication/overview/Checkout'));
const ForArtist = lazy(() => import('../../container/profile/authentication/overview/ForArtist'));
const ForCollector = lazy(() => import('../../container/profile/authentication/overview/ForCollector'));
const Project = lazy(() => import('../../container/project/Project'));
const TeasteQuizProject = lazy(() => import('../../container/project/TasteQuizProject'));
const ProjectDetails = lazy(() => import('../../container/project/ProjectDetails'));
const TasteQuiz = lazy(() => import('../../container/profile/authentication/overview/TasteQuiz'));
const Orders = lazy(() => import('../../container/profile/settings/overview/Orders'));

const ManageExhibition = lazy(() => import('../../container/profile/settings/overview/ManageExhibition'));
const ManageBlog = lazy(() => import('../../container/blogs/ManageBlog'));
const HoldOrders  = lazy(() => import('../../container/profile/settings/overview/HoldOrders'));

const Gallery = lazy(() => import('../../container/profile/authentication/overview/Gallery'));
const SucessfulPurchase  = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/SucessfulPurchase'));
const PolicyPage  = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/PolicyPage'));
const OurPartners = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/OurPartners'));
const Exhibitions = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/Exhibitions'));
const ExhibitionList = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/ExhibitionList'));
const BlogList = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/BlogList'));
const EducationList = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/EducationList'));
const Terms  = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/Terms'));
const ArtWorkDetails  = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/ArtWorkDetails'));
const ArtGallery = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/ArtGallery'));
const ArtistGrid = lazy(() => import('../../container/profile/authentication/overview/GalleryComponents/ArtistGrid'));
const ManageNewsEvents = lazy(() => import('../../container/newsevents/ManageNewsEvents'));
import HomePageContent from '../../container/profile/authentication/overview/GalleryComponents/HomePageContent';
import CustomShare from '../../container/profile/authentication/overview/GalleryComponents/CustomShare';
import ManageEducation from '../../container/educations/ManageEducation';
import ManageCustomURL from '../../container/customURL/ManageCustomURL';
import DataNotFound from '../../container/profile/authentication/overview/GalleryComponents/DataNotFound';
import NewsEventList from '../../container/profile/authentication/overview/GalleryComponents/NewsEventList';
import EducationDetails from '../../container/educations/EducationDetails';
import BlogDetails from '../../container/blogs/BlogDetails';
import NewsDetails from '../../container/newsevents/NewsDetails';
import ManageSEO from '../../container/meta/ManageSEO';
import NewsEventDetails from '../../container/profile/authentication/overview/GalleryComponents/NewsEventDetails';
import ArtistDetails from '../../container/profile/authentication/overview/GalleryComponents/ArtistDetails';
import PrivateMembership from '../../container/profile/authentication/overview/GalleryComponents/PrivateMembership';
import ManageEarlyAccess from '../../container/profile/settings/overview/ManageEarlyAccess';
import EarlyAccessDetail from '../../container/profile/authentication/overview/GalleryComponents/EarlyAccessDetail';
import ConsultationDetails from '../../container/Consultation/ConsultationDetails';


const Users = () => {
  const { path } = useRouteMatch();

  const NotFound = () => {
    //alert(123)
    return <Redirect to="/" />;
  };

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path={`/taste-test-quiz`} component={LoginTasteQuiz} />
        <Route exact path={`/gallery`} component={LoginHomeGallery} />

        <Route exact path={`/`} component={HomePageContent} />
        <Route exact path={`/new-gallery`} component={Gallery} />
        <Route exact path={`/checkout`} component={Checkout} />
        <Route exact path={`/for-artists`} component={ForArtist} />
        <Route exact path={`/for-collectors`} component={ForCollector} />
        <Route exact path={`/all-artworks`} component={ArtGallery} />
        <Route exact path="/all-artworks/:filterValues" component={ArtGallery} />
        <Route exact path="/all-artworks/:share_url/:share_id" component={ArtGallery} />
        <Route exact path={`/artists`} component={ArtistGrid} />
        <Route exact path={`/artists/:artistId`} component={ArtistDetails} />
        <Route exact path={`/project`} component={Projects1} />
        <Route exact path={`/profile`} component={Settings} />
        <Route exact path={`/invite`} component={Invite} />
        <Route exact path={`/create-slots`} component={CreateSlots} />
        
        <Route exact path={`/users`} component={UserList} />
        <Route exact path={`/cart`} component={Cart} />
        <Route exact path={`/art-works`} component={ArtWorks} />
        <Route exact path={`/admin-art-works`} component={ArtWorks} />
        <Route exact path={`/my-ratings`} component={WorkRatingList} />
        <Route exact path={`/my-works/grid`} component={Project} />
        <Route exact path={`/taste-quiz`} component={TasteQuiz} />
        <Route exact path={`/admin-taste-test`} component={TeasteQuizProject} />
        <Route exact path={`/stoodio-snippet`} component={ForHomeSnippet} />
        <Route exact path={`/city-snippet`} component={ForCitySnippet} />
        <Route exact path={`/queue-snippet`} component={ForQueueSnippet} />
        <Route exact path={`/artist-snippet`} component={ForArtistSnippet} />
        <Route exact path={`/collector-snippet`} component={ForCollectorSnippet} />
        <Route exact path={`/project/projectDetails/:id`} component={ProjectDetails} />
        <Route exact path={`/admin-account`} component={AdminAccount} />
        <Route exact path={`/checkOut-snippet`} component={ForCheckOutSnippet} />
        <Route exact path={`/purchase-sucess`} component={SucessfulPurchase} />
        <Route exact path="/schedule-art-consultation" component={ConsultationDetails} />
        <Route path={`/data-not-found`} component={DataNotFound} />
        <Route exact path={`/privacy-policy`} component={PolicyPage} />
        <Route exact path="/our-partners" component={OurPartners} />
        <Route exact path="/exhibitions/:exhibitionname" component={Exhibitions} />
        {/* <Route exact path="/early-access/:early_access_id" component={EarlyAccessDetail} /> */}
        <Route exact path="/early-access" component={EarlyAccessDetail} />
        <Route exact path="/exhibitions" component={ExhibitionList} />
        <Route exact path="/blogs" component={BlogList} />
        <Route exact path="/education" component={EducationList} />
        <Route exact path="/education/:url" component={EducationDetails} />
        <Route exact path="/blogs/:url" component={BlogDetails} />
        <Route exact path="/artworks/:SearchValues" component={CustomShare} />
        <Route exact path={`/terms`} component={Terms} />
        <Route exact path={`/reset-password`} component={NotFound} />
        <Route exact path={`/my-purchases`} component={Orders} />
        <Route exact path={`/manage-exhibition`} component={ManageExhibition} />
        <Route exact path={`/manage-early-access`} component={ManageEarlyAccess} />
        <Route exact path={`/manage-seo-page`} component={ManageSEO} />
        <Route exact path={`/manage-blogs`} component={ManageBlog} />
        <Route exact path={`/manage-custom-url`} component={ManageCustomURL} />
        <Route exact path={`/manage-education`} component={ManageEducation} />
        <Route exact path={`/hold-orders`} component={HoldOrders} />
        <Route exact path={`/artwork-for-sale/:artworkname`} component={ArtWorkDetails} />
        <Route exact path={`/manage-news-events`} component={ManageNewsEvents} />
        <Route exact path="/news-events" component={NewsEventList} />
        <Route exact path="/news-events/:url" component={NewsDetails} />
        <Route exact path="/art-event-details/:event_title" component={NewsEventDetails} />
        {/* <Route exact path="/early-work" component={PrivateMembership} /> */}
        
        {/* <Route path={`${path}`} component={Settings} /> */}
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Users);
